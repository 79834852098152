import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Result.css';
import axios from 'axios';

function Result() {
    const location = useLocation();
    const responseData = location.state?.responseData["message"];

    const [contactRequested, setContactRequested] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [videoLoading, setVideoLoading] = useState(true); 

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
            !function(f,b,e,v,n,t,s) {
                if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                n.callMethod.apply(n,arguments) : n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)
            }(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '219249273718696');
            fbq('track', 'PageView');
        `;
        document.head.appendChild(script);

        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=219249273718696&ev=PageView&noscript=1"/>`;
        document.head.appendChild(noscript);

        return () => {
            document.head.removeChild(script);
            document.head.removeChild(noscript);
        };
    }, []);

    if (!responseData) {
        return (
            <div className="result-container">
                <h2>.לא התקבלו נתונים</h2>
                <h3>.נסה שנית מאוחר יותר</h3>
            </div>
        );
    }

    const {
        full_name,
        financial_potential,
        human_financial_potential,
        human_annual_bank_loss,
    } = responseData;

    const handleContactRequest = async () => {
        setLoading(true);
        try {
            // https://m6x371ibtf.execute-api.eu-north-1.amazonaws.com/dev/registerUser
            // http://192.168.1.21:5001/registerUser
            const response = await axios.post('https://m6x371ibtf.execute-api.eu-north-1.amazonaws.com/dev/registerUser', { responseData });
            if (response.status === 200) {
                console.error("1")
                setContactRequested(true);
            } 
            else {
                console.error("2")
                setRequestError(true);
            }
        } catch (error) {
            console.error("3")
            setRequestError(true);
            console.error('Error adding user to database:', error);
        } finally {
            setLoading(false);
        }
    };

    const renderFinancialPotentialContent = () => {
        if (financial_potential === 0) {
            return (
                <>
                    <h2 className="result-heading">{full_name}</h2>
                    <h4 className="result-subheading">,לפי הנתונים שלך כרגע <br />אנחנו צריכים לעבוד על סדר ותוכנית</h4>
                    <h4 className="result-subheading">ולצאת למצב של<br /><span className="green-highlight">יציבות כלכלית וביטחון</span></h4>
                    <h4 className="result-subheading">ובשלב הבא <br />לתת לכסף שלך לייצר עוד כסף</h4>
                    <p className="result-text">
                        <span className="blue-highlight">?בא לך להפסיד לדאוג מכסף</span>
                    </p>
                </>
            );
        }

        return (
            <>
                {/* <h2 className="result-heading">{full_name}<br />!יש לך נתונים אישיים טובים</h2> */}
                <h2 className="result-heading">: תוצאות הבדיקה שלך<br />{full_name}<br/>!יש לך נתונים אישיים טובים</h2>

                <h4 className="result-subheading">,עם תוכנית מדויקת שנבנה לך <br />:הפוטנציאל הכלכלי שלך הוא</h4>
                <p className="green-highlight">₪ {human_financial_potential}</p>
                <p className="result-text">
                    אם תחסוך ותשאיר את הכסף שלך בבנק <br />
                    יש פה הפסד של <span className="red-highlight">{human_annual_bank_loss}</span> ש״ח בכל שנה <br />
                    כי בבנק ערך הכסף <span className="red-highlight">הולך ויורד</span> כל הזמן
                </p>
            </>
        );
    };

    return (
        <div className="result-container">
            <div className="result-content">
                {renderFinancialPotentialContent()}
            </div>
            <div className="button-container">
                {!contactRequested && !requestError && !loading && (
                    <button onClick={handleContactRequest} className="contact-button">
                        {financial_potential === 0 ? '!אני רוצה שיחת ייעוץ חינמית' : '!אני רוצה שיחת ייעוץ חינמית'}
                    </button>
                )}
                {loading && (
                    <div className="spinner-container">
                        <div className="spinner"></div>
                    </div>
                )}
                {contactRequested && (
                    <>
                        <p className="blue-highlight">!הפרטים נקלטו, נחזור אליכם בהקדם</p>
                        <div className="video-container">
                            {videoLoading && (
                                <div className="video-overlay">
                                    <div className="spinner"></div>
                                </div>
                            )}
                            <iframe
                                className="video"
                                src="https://www.youtube.com/embed/aCH532rUl-8?si=Lxa08lpAaiS8ptOI"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                                allowfullscreen
                                onLoad={() => setVideoLoading(false)} // הסרה מיידית של הכיסוי לאחר טעינת הסרטון
                            ></iframe>
                        </div>
                    </>
                )}
                {requestError && <p className="blue-highlight">!אירעה שגיאה, נסה שנית מאוחר יותר</p>}
            </div>
        </div>
    );
}

export default Result;
