import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import './Home.css';

Modal.setAppElement('#root');

function calculate_finance_potential(data) {
    var { full_name, age, salary, expenses, monthly_savings, phone_number, initial_savings } = data;

    const interest_rate = 0.09;
    const deposit_years = 12;
    const n = 12;

    salary = parseFloat(salary);
    expenses = parseFloat(expenses);
    monthly_savings = parseFloat(monthly_savings);
    initial_savings = parseFloat(initial_savings);

    if (salary <= expenses) {
        data["financial_potential"] = 0;
        data["annual_bank_loss"] = 0;
    } else {
        var financial_potential = Math.round(initial_savings * (1 + interest_rate / n) ** (n * deposit_years) + monthly_savings * (((1 + interest_rate / n) ** (n * deposit_years) - 1) / (interest_rate / n)));
        var annual_bank_loss = (monthly_savings * 12) * 0.05;

        financial_potential = parseFloat(financial_potential.toFixed(2));
        annual_bank_loss = parseFloat(annual_bank_loss.toFixed(2));

        data["financial_potential"] = financial_potential;
        data["annual_bank_loss"] = annual_bank_loss;
        data["human_financial_potential"] = financial_potential.toLocaleString();
        data["human_annual_bank_loss"] = annual_bank_loss.toLocaleString();
    }

    data["interest_rate"] = interest_rate;
    data["deposit_years"] = deposit_years;

    console.log("----Start-----");
    console.log(data);
    console.log("----Stop-----");
    const message = { "message": { ...data } };

    return message;
}

function Home() {
    
    const [formData, setFormData] = useState({
        full_name: '',
        age: '',
        salary: '',
        expenses: '',
        monthly_savings: '',
        phone_number: '',
        initial_savings: '0'
    });

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const [nameError, setNameError] = useState(false);
    const [ageError, setAgeError] = useState(false);
    const [salaryError, setSalaryError] = useState(false);
    const [expensesError, setExpensesError] = useState(false);
    const [monthlySavingsError, setMonthlySavingsError] = useState(false);
    const [initialSavingsError, setInitialSavingsError] = useState(false);
    const [generalErrorMessage, setGeneralErrorMessage] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(true); // מצב חדש לתיבת הסימון, מסומן כברירת מחדל
    const [acceptTermsError, setAcceptTermsError] = useState(false); // מצב לשגיאה בתיבת הסימון
    const navigate = useNavigate();

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
            !function(f,b,e,v,n,t,s) {
                if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                n.callMethod.apply(n,arguments) : n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)
            }(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '219249273718696');
            fbq('track', 'PageView');
        `;
        document.head.appendChild(script);

        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=219249273718696&ev=PageView&noscript=1"/>`;
        document.head.appendChild(noscript);

        return () => {
            document.head.removeChild(script);
            document.head.removeChild(noscript);
        };
    }, []);

    const fieldLabels = {
        full_name: 'שם מלא',
        age: 'גיל',
        salary: 'משכורת',
        expenses: 'הוצאות',
        monthly_savings: 'כמות הכסף שאתה חוסך בממוצע כל חודש',
        phone_number: 'מספר טלפון',
        initial_savings: 'הון התחלתי'
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'full_name') {
            const isValid = /^[a-zA-Z\s\u0590-\u05FF]*$/.test(value) &&
                !/ {2}/.test(value) &&
                (value.match(/ /g) || []).length <= 2 &&
                value.length <= 25 &&
                !/['\u05F3]/.test(value); // '\u05F3' is the Hebrew single quote (geresh)
            if (!isValid) return;
        } else if (name === 'age') {
            const isValid = /^[0-9]{0,3}$/.test(value);
            if (!isValid) return;
        } else if (['salary', 'expenses', 'monthly_savings', 'initial_savings'].includes(name)) {
            const isValid = /^(\d+(\.\d{0,3})?)?$/.test(value) && value.length <= 11;
            if (!isValid) return;
        } else if (name === 'phone_number') {
            const isValid = /^[0-9]{0,10}$/.test(value);
            if (!isValid) return;
        }

        setFormData({ ...formData, [name]: value });
    };

    const validateFullName = (name) => {
        const isValid = /^[a-zA-Z\u0590-\u05FF]+(?: [a-zA-Z\u0590-\u05FF]+)*$/.test(name) &&
            !/ {2}/.test(name) &&
            name.length <= 25 &&
            name.length >= 2 &&
            !/['\u05F3]/.test(name); // '\u05F3' is the Hebrew single quote (geresh)
        return isValid;
    };

    const validateAge = (age) => {
        const isValid = /^[0-9]{1,3}$/.test(age) && parseInt(age) < 120;
        return isValid;
    };

    const validateNumberField = (value) => {
        const isValid = /^[1-9][0-9]*(\.[0-9]+)?$|^0(\.[0-9]+)?$/.test(value) && value.length <= 11 && !value.startsWith('.') && !value.endsWith('.');
        return isValid;
    };

    const validatePhoneNumber = (phoneNumber) => {
        const isValid = /^0[0-9]{9}$/.test(phoneNumber);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        resetZoom();

        const trimmedFullName = formData.full_name.trim();

        let hasError = false;
        let errorMessages = [];

        if (!validateFullName(trimmedFullName)) {
            setNameError(true);
            errorMessages.push(fieldLabels.full_name);
            hasError = true;
        } else {
            setNameError(false);
        }

        if (!validateAge(formData.age)) {
            setAgeError(true);
            errorMessages.push(fieldLabels.age);
            hasError = true;
        } else {
            setAgeError(false);
        }

        const fieldsToCheck = ['salary', 'expenses', 'monthly_savings', 'initial_savings'];
        fieldsToCheck.forEach((field) => {
            if (!validateNumberField(formData[field])) {
                if (field === 'salary') setSalaryError(true);
                if (field === 'expenses') setExpensesError(true);
                if (field === 'monthly_savings') setMonthlySavingsError(true);
                if (field === 'initial_savings') setInitialSavingsError(true);
                errorMessages.push(fieldLabels[field]);
                hasError = true;
            } else {
                if (field === 'salary') setSalaryError(false);
                if (field === 'expenses') setExpensesError(false);
                if (field === 'monthly_savings') setMonthlySavingsError(false);
                if (field === 'initial_savings') setInitialSavingsError(false);
            }
        });

        if (!validatePhoneNumber(formData.phone_number)) {
            setPhoneError(true);
            errorMessages.push(fieldLabels.phone_number);
            hasError = true;
        } else {
            setPhoneError(false);
        }

        if (!acceptTerms) {
            setAcceptTermsError(true);
            errorMessages.push("תיבת סימון תנאים");
            hasError = true;
        } else {
            setAcceptTermsError(false);
        }

        if (hasError) {
            if (errorMessages.length > 1) {
                setGeneralErrorMessage('הערכים בשדות המסומנים לא תקינים. יש לשנות ולנסות מחדש');
            } else {
                setGeneralErrorMessage(`הערך שהכנסת בשדה '${errorMessages[0]}' לא תקין`);
            }
            setErrorModalIsOpen(true);
            return;
        }

        setLoading(true);
        try {
            const updatedFormData = { ...formData, full_name: trimmedFullName };
            const new_data = calculate_finance_potential(updatedFormData);
            setSuccessMessage('... הפוטנציאל הכלכלי בדרך אליך');
            setErrorMessage('');
            setModalIsOpen(true);
            navigate('/result', { state: { responseData: new_data } });
        } catch (error) {
            console.error('Error sending data:', error);
            setSuccessMessage('');
            setErrorMessage('!אירעה שגיאה בשליחת הנתונים, נסה שנית');
            setModalIsOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const resetZoom = () => {
        if (document.activeElement) {
            document.activeElement.blur();
        }
        window.scrollTo(0, 0);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setErrorModalIsOpen(false);
    };

    return (
        <div className="App">
            <h1 style={{ fontFamily: 'Heebo', fontWeight: '900', textAlign: 'center' }}>גלו את הפוטנציאל הכלכלי שלכם, תוך 45 שניות</h1>
            <h3 className='home-heading'>מחשבון חכם חינמי מאת אליאור זכאים</h3>

            <form onSubmit={handleSubmit} className="form-container">
                <div className="form-group">
                    <label>שם מלא:</label>
                    <input
                        type="text"
                        name="full_name"
                        value={formData.full_name}
                        onChange={handleChange}
                        style={{ fontSize: '16px', borderColor: nameError ? 'red' : '' }}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>גיל:</label>
                    <input
                        type="text"
                        name="age"
                        value={formData.age}
                        onChange={handleChange}
                        style={{ fontSize: '16px', borderColor: ageError ? 'red' : '' }}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>משכורת (נטו) :</label>
                    <input
                        type="text"
                        name="salary"
                        value={formData.salary}
                        onChange={handleChange}
                        style={{ fontSize: '16px', borderColor: salaryError ? 'red' : '' }}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>הוצאות:</label>
                    <input
                        type="text"
                        name="expenses"
                        value={formData.expenses}
                        onChange={handleChange}
                        style={{ fontSize: '16px', borderColor: expensesError ? 'red' : '' }}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>סך הכסף שאת/ה חוסכ/ת בחודש (בערך):</label>
                    <input
                        type="text"
                        name="monthly_savings"
                        value={formData.monthly_savings}
                        onChange={handleChange}
                        style={{ fontSize: '16px', borderColor: monthlySavingsError ? 'red' : '' }}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>הון התחלתי:</label>
                    <input
                        type="text"
                        name="initial_savings"
                        value={formData.initial_savings}
                        onChange={handleChange}
                        style={{ fontSize: '16px', borderColor: initialSavingsError ? 'red' : '' }}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>מספר טלפון:</label>
                    <input
                        type="tel"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        style={{ fontSize: '16px', borderColor: phoneError ? 'red' : '' }}
                        required
                    />
                    {phoneError && <div style={{ color: 'red' }}>{phoneError}</div>}
                </div>
                <div className="checkbox-group">
                    <input
                        type="checkbox"
                        id="acceptTerms"
                        checked={acceptTerms}
                        onChange={() => setAcceptTerms(!acceptTerms)}
                        required
                    />
                    <label htmlFor="acceptTerms"> מאשר/ת לקבל דיוור בנייד, במייל ו-SMS</label>
                </div>
                {acceptTermsError && <div style={{ color: 'red' }}>יש לסמן את תיבת הסימון כדי להמשיך</div>}
                <button type="submit" style={{ fontSize: '16px', backgroundColor : '#0d730d' }} className="submit-button">אני רוצה לדעת את האמת!</button>
            </form>
            {loading && <div className="spinner-container"><div className="spinner"></div></div>}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Success Message"
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2>{successMessage ? '!הפרטים נקלטו' : 'שגיאה'}</h2>
                <p>{successMessage || errorMessage}</p>
                <button onClick={closeModal}>סגור</button>
            </Modal>
            <Modal
                isOpen={errorModalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Error Message"
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2>שגיאה</h2>
                <p>{generalErrorMessage}</p>
                <button onClick={closeModal}>סגור</button>
            </Modal>
        </div>
    );
}

export default Home;
